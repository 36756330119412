:root {
  --body-background-color: #FFF;
  --paper-background-color: #fafafa;

  --nav-rail-width: 90px;
  --nav-rail-submenu-width: 230px;

  --translate-height: 40px;
  --search-bar-height: 40px;
  --warning-height: 0px;
  --breadcrumbs-bar-height: 40px;
  --code-toolbar-height: 53px;

  --banner-height: 106px;
  --footer-height: 40px;
  --page-controls-width: 358px;
  --content-width: 956px;

  --nav-dark-background-color: #333;
  --nav-dark-hovered-background-color: #414141;
  --nav-dark-expanded-background-color: #626262;
  --nav-dark-text-color: #C1C1C1;
  --nav-dark-hovered-text-color: #FFF;
  --nav-dark-expanded-text-color: #FFF;
  --nav-dark-disabled-text-color: #A6A6A6;
  --nav-dark-border-color: #444444;

  --nav-light-background-color: #F3F6FC;
  --nav-light-hovered-background-color: #E5E5E5;
  --nav-light-expanded-background-color: #C4C4C4;
  --nav-light-text-color: #454545;
  --nav-light-hovered-text-color: #1F1F1F;
  --nav-light-expanded-text-color: #1F1F1F;
  --nav-light-disabled-text-color: #A6A6A6;
  --nav-light-border-color: #E1E1E1;

  --nav-background-color: var(--nav-dark-background-color);
  --nav-hovered-background-color: var(--nav-dark-hovered-background-color);
  --nav-expanded-background-color: var(--nav-dark-expanded-background-color);
  --nav-text-color: var(--nav-dark-text-color);
  --nav-hovered-text-color: var(--nav-dark-hovered-text-color);
  --nav-expanded-text-color: var(--nav-dark-expanded-text-color);
  --nav-disabled-text-color: var(--nav-dark-disabled-text-color);
  --nav-border-color: var(--nav-dark-border-color);

  --accent-color: #49A942;
  --accent-color-light: #3ABB30;
  --accent-color-lighter: #2BCC1F;
  --accent-color-dark: #589754;
  --accent-color-darker: #678665;
  --accent-color-contrast: #333333;
  --accent-color-contrast-faded: #4D4D4D;

  --nav-accent-color: var(--accent-color);
  --nav-accent-color-light: var(--accent-color-light);
  --nav-accent-color-lighter: var(--accent-color-lighter);
  --nav-accent-color-dark: var(--accent-color-dark);
  --nav-accent-color-darker: var(--accent-color-darker);
  --nav-accent-color-contrast: var(--accent-color-contrast);
  --nav-accent-color-contrast-faded: var(--accent-color-contrast-faded);

  --accent-border-color: #49A942;
  --accent-border-color-light: #3ABB30;
  --accent-border-color-lighter: #2BCC1F;
  --accent-border-color-dark: #589754;
  --accent-border-color-darker: #678665;
  --accent-border-color-contrast: #333333;
  --accent-border-color-contrast-faded: #4D4D4D;

  --accent-highlight-color: #49A942;
  --accent-highlight-color-light: #3ABB30;
  --accent-highlight-color-lighter: #2BCC1F;
  --accent-highlight-color-dark: #589754;
  --accent-highlight-color-darker: #678665;
  --accent-highlight-color-contrast: #333333;
  --accent-highlight-color-contrast-faded: #4D4D4D;

  --accent-line-color: #49A942;
  --accent-line-color-light: #3ABB30;
  --accent-line-color-lighter: #2bCC1F;
  --accent-line-color-dark: #589754;
  --accent-line-color-darker: #678665;
  --accent-line-color-contrast: #333333;
  --accent-line-color-contrast-faded: #4D4D4D;

  --accent-text-color: #005D85;
  --accent-text-color-light: #005D85;
  --accent-text-color-lighter: #005D85;
  --accent-text-color-dark: #0A597B;
  --accent-text-color-darker: #145571;
  --accent-text-color-contrast: #FFFFFF;
  --accent-text-color-contrast-faded: #D9D9D9;

  --banner-color: #0B5940;
  --banner-color-light: #036143;
  --banner-color-lighter: #006444;
  --banner-color-dark: #12523D;
  --banner-color-darker: #1A4A3B;
  --banner-color-contrast: #FFFFFF;
  --banner-color-contrast-faded: #D9D9D9;

  --search-bar-top: 0px;
  --warnings-bar-top: calc(var(--search-bar-top) + var(--search-bar-height));
  --warnings-bar-height: var(--warning-height);
  --breadcrumbs-bar-top: calc(var(--warnings-bar-top) + var(--warnings-bar-height));
  --page-content-top: calc(var(--breadcrumbs-bar-top) + var(--breadcrumbs-bar-height));
  --code-toolbar-top: var(--page-content-top);
  --code-content-top: calc(var(--code-toolbar-top) + var(--code-toolbar-height));

}
